import React from 'react';
import ThearthifyLandingPage from './components/ThearthifyLandingPage';
import ProductPage from './components/ProductPage';
import ProductInquiryForm from './components/ProductInquiryForm'
import Blog from './components/Blog';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<ThearthifyLandingPage />} />
        <Route path='/products' element={<ProductPage />} />
        <Route path='/inquiry' element={<ProductInquiryForm />} />
        <Route path='/blog' element={<Blog />} />
      </Routes>
    </Router>
  );
}

export default App;