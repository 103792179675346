import React from 'react';
import { useNavigate } from 'react-router-dom';
import TopBar from './TopBar'; // Import the TopBar component

const Blog = () => {
  const navigate = useNavigate();

  // Sample blog post data (you can replace this with dynamic data fetching)
  const blogPost = {
    title: "ThEarthify Journey: Transforming Every Product's Life Cycle for a Greener Tomorrow",
    date: "March 8, 2025",
    content: [
      {
        type: "paragraph",
        text: "At ThEarthify, we believe every product has a story—a journey that begins with the earth itself. Our mission is to redefine sustainability by carefully curating each step, from responsible sourcing to eco-friendly disposal. With the global sustainable packaging market projected to grow at a CAGR of 7.6% from 2024 to 2030 (Grand View Research), we strive to be at the forefront of this movement."
      },
      {
        type: "paragraph",
        text: "Serving B2B and B2C markets across India, Canada, and the United States, our innovative range—from clamshell containers to straws—is designed with one mission: a greener tomorrow. Let's explore how we infuse every stage of a product's life cycle with the values that make ThEarthify truly unique."
      },
      {
        type: "section",
        title: "1. Sourcing: Harvesting Sustainability from Nature",
        content: [
          {
            type: "paragraph",
            text: "Every great product begins with a commitment to sustainability. Our journey starts at the source, where we transform a natural byproduct into a resource for change."
          },
          {
            type: "bullet",
            text: "Bagasse Brilliance: We use bagasse, the fibrous residue from sugarcane processing, as our primary raw material. This not only reduces agricultural waste but also provides an eco-friendly alternative to plastic packaging, which constituted nearly 59% of India's total plastic consumption in 2019 (ICCE)."
          },
          {
            type: "bullet",
            text: "Responsibly Sourced: Our materials are collected with a focus on environmental integrity, ensuring that our packaging is both renewable and sustainable. With only 15% of India's plastic packaging waste effectively recycled (ICCE), our biodegradable products offer a much-needed alternative."
          }
        ]
      },
      {
        type: "section",
        title: "2. From Bagasse to Brilliance: Eco-Conscious Manufacturing",
        content: [
          {
            type: "paragraph",
            text: "The transformation of raw bagasse into high-quality packaging is a testament to our innovation and dedication to the planet."
          },
          {
            type: "bullet",
            text: "Eco-Engineered Production: Our state-of-the-art manufacturing processes minimize waste and conserve energy, aligning with industry-leading eco-friendly practices."
          },
          {
            type: "bullet",
            text: "Quality You Can Trust: Each product is bacteria-free, non-toxic, food grade, and rigorously quality control checked. Plus, our packaging is microwave, oven, and freezer safe—ensuring durability and reliability for your everyday needs. With 39% of consumers indicating that eco-friendly packaging influences their purchasing decisions (McKinsey & Company), we ensure our products meet their expectations."
          }
        ]
      },
      {
        type: "section",
        title: "3. Green Distribution: Delivering with a Light Footprint",
        content: [
          {
            type: "paragraph",
            text: "Getting our sustainable packaging to you is as important as how it's made."
          },
          {
            type: "bullet",
            text: "Optimized Logistics: By refining distribution strategies, we cut down on emissions and carbon footprint, ensuring a more sustainable supply chain."
          },
          {
            type: "bullet",
            text: "Global Reach with Local Impact: While our markets span India, Canada, and the USA, our distribution model emphasizes reducing transit distances and supporting local logistics networks wherever possible."
          }
        ]
      },
      {
        type: "section",
        title: "4. Safe and Sustainable Use: Empowering You Every Day",
        content: [
          {
            type: "paragraph",
            text: "Our commitment to sustainability extends well beyond production and delivery. When you choose Thearthify, you're opting for packaging that supports both environmental stewardship without compromising on functionality."
          },
          {
            type: "bullet",
            text: "User-Friendly Design: Our products are designed for immediate use, whether it's for a bustling restaurant or a cozy home meal. They are oil and water proof, ensuring that every meal is both safe and mess-free."
          },
          {
            type: "bullet",
            text: "Empowering Change: According to a report by the EPA, with biodegradable packaging reducing landfill waste by up to 60% (EPA), your choice of eco-friendly alternatives contributes to a healthier planet."
          }
        ]
      },
      {
        type: "section",
        title: "5. End-of-Life: Embracing the Circular Economy",
        content: [
          {
            type: "paragraph",
            text: "A truly sustainable product doesn't end its journey in a landfill—it transforms to give back to nature."
          },
          {
            type: "bullet",
            text: "Biodegradable Promise: Our packaging is 100% biodegradable, meaning it naturally decomposes without leaving harmful residues behind."
          },
          {
            type: "bullet",
            text: "From Waste to Worth: Once your product has served its purpose, it can be safely composted or recycled, reducing waste and promoting environmental renewal."
          }
        ]
      },
      {
        type: "conclusion",
        title: "Join the Movement for a Greener Future!",
        text: "When you choose ThEarthify, you're making a powerful statement for sustainability. With the sustainable packaging industry expected to continue its rapid growth (Grand View Research), now is the perfect time to switch to eco-friendly alternatives. Explore our biodegradable solutions today and take a step toward a greener future! Follow us on Instagram @fernandesearthifypvt to see how we're shaping a world where every product's life cycle leaves a positive environmental impact."
      }
    ]
  };

  return (
    <div>
      {/* Header */}
      <TopBar />

      <div className="min-h-screen bg-emerald-50 py-12 px-4">
        <div className="max-w-4xl mx-auto">
          {/* Featured Image */}
          <div className="w-full bg-emerald-600 rounded-lg overflow-hidden mb-8">
            <img 
              src="/assets/blog1.png" 
              alt="ThEarthify Sustainable Packaging" 
              className="w-full object-contain"
            />
          </div>
          {/* Blog Content */}
          <div className="bg-white rounded-lg shadow-xl p-8 mb-8">
            <div className="mb-6">
              <h1 className="text-3xl md:text-4xl font-bold text-emerald-800 mb-4">{blogPost.title}</h1>
              <div className="flex items-center text-emerald-600 mb-4">
                <span className="text-sm">{blogPost.date}</span>
                <span className="mx-2">•</span>
                <span className="text-sm">Sustainability</span>
              </div>
              <div className="w-16 h-1 bg-green-600 mb-6"></div>
            </div>

            {/* Blog Content Rendering */}
            <div className="prose max-w-none">
              {blogPost.content.map((section, index) => {
                if (section.type === "paragraph") {
                  return <p key={index} className="text-gray-700 mb-6">{section.text}</p>;
                } else if (section.type === "section") {
                  return (
                    <div key={index} className="mb-8">
                      <h2 className="text-2xl font-bold text-emerald-700 mb-4">{section.title}</h2>
                      {section.content.map((item, i) => {
                        if (item.type === "paragraph") {
                          return <p key={i} className="text-gray-700 mb-4">{item.text}</p>;
                        } else if (item.type === "bullet") {
                          return (
                            <div key={i} className="flex mb-3">
                              <div className="text-green-600 mr-2">•</div>
                              <p className="text-gray-700">{item.text}</p>
                            </div>
                          );
                        }
                        return null;
                      })}
                    </div>
                  );
                } else if (section.type === "conclusion") {
                  return (
                    <div key={index} className="mt-8 p-6 bg-emerald-50 rounded-lg border-l-4 border-green-600">
                      <h2 className="text-2xl font-bold text-emerald-700 mb-4">{section.title}</h2>
                      <p className="text-gray-700">{section.text}</p>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>

          {/* Call to Action */}
          <div className="bg-green-600 text-white rounded-lg p-8 text-center">
            <h3 className="text-2xl font-bold mb-4">Ready to Make a Difference?</h3>
            <p className="mb-6">Explore our range of sustainable packaging solutions and join the movement toward a greener future.</p>
            <button 
              onClick={() => navigate('/inquiry')}
              className="bg-white text-green-600 hover:bg-emerald-100 font-bold py-3 px-8 rounded-md transition duration-200"
            >
              Contact Us Today
            </button>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-green-800 text-white p-4">
        <div className="container mx-auto text-center">
          <p>&copy; 2024 ThEarthify. All rights reserved.</p>
          <p>Together, let's make a greener future!</p>
        </div>
      </footer>
    </div>
  );
};

export default Blog;