import React, { useRef } from 'react';
import { ArrowRight } from 'lucide-react';
import TopBar from './TopBar';
import { useNavigate } from 'react-router-dom';

const ThearthifyLandingPage = () => {
    const navigate = useNavigate();
    const drawerCheckboxRef = useRef(null); // Create a ref for the drawer checkbox

    // Function to handle menu item click
    const handleMenuItemClick = () => {
        if (drawerCheckboxRef.current) {
            drawerCheckboxRef.current.checked = false; // Uncheck the drawer to close it
        }
        document.getElementById("main-content").focus(); // Focus back to the main content
    };

    return (
        <div className="min-h-screen bg-emerald-50 text-emerald-900 flex flex-col">
            <TopBar />
            <header className="bg-white text-black p-4 shadow-md">
                <div className="container mx-auto flex justify-between items-center">
                    {/* Logo Section (Centered on Smaller Screens) */}
                    <div className="flex items-center space-x-2 mb-4 sm:mb-0 w-full sm:w-auto justify-center sm:justify-start">
                        <img
                            src="/assets/IMG_6852.jpg"
                            alt="ThEarthify Logo"
                            style={{ width: '12rem', height: '4.5rem' }}
                            className="object-contain"
                        />
                    </div>

                    {/* Drawer Toggle Button (Hamburger Menu for Mobile) */}
                    <div className="sm:hidden flex items-center justify-start mr-[10%]">
                        <label htmlFor="my-drawer" className="drawer-button flex flex-col space-y-1 ml-4">
                            <div className="w-6 h-1 bg-black rounded"></div>
                            <div className="w-6 h-1 bg-black rounded"></div>
                            <div className="w-6 h-1 bg-black rounded"></div>
                        </label>
                    </div>

                    {/* DaisyUI Drawer for Hamburger Menu */}
                    <input
                        id="my-drawer"
                        type="checkbox"
                        className="drawer-toggle"
                        ref={drawerCheckboxRef} // Attach the ref to the checkbox
                    />
                    <div className="drawer-content">
                        {/* Full Navigation Menu for Larger Screens */}
                        <nav className="w-full sm:w-auto hidden sm:flex justify-center sm:justify-end space-x-6">
                            <ul className="menu menu-horizontal px-1 font-bold">
                            <li><button className="hover:text-primary" onClick={() => navigate('/blog')}>Blog</button></li>
                                <li><a href="#about" className="hover:text-primary" onClick={handleMenuItemClick}>About</a></li>
                                <li><a href="#team" className="hover:text-primary" onClick={handleMenuItemClick}>Team</a></li>
                                <li><a href="#products" className="hover:text-primary" onClick={handleMenuItemClick}>Products</a></li>
                            </ul>
                        </nav>
                    </div>

                    {/* Drawer Menu for Mobile */}
                    <div className="drawer-side">
                        <label htmlFor="my-drawer" className="drawer-overlay"></label>
                        <ul className="menu p-4 w-64 bg-base-100 text-base-content">
                        <li><button className="hover:text-primary" onClick={() => navigate('/blog')}>Blog</button></li>
                            <li><a href="#about" className="hover:text-primary" onClick={handleMenuItemClick}>About</a></li>
                            <li><a href="#team" className="hover:text-primary" onClick={handleMenuItemClick}>Team</a></li>
                            <li><a href="#products" className="hover:text-primary" onClick={handleMenuItemClick}>Products</a></li>
                        </ul>
                    </div>
                </div>
            </header>

            {/* Main Content */}
            <main id="main-content" className="flex-grow container mx-auto px-4 py-8" tabIndex="-1">
                {/* Hero Section */}
                <section className="text-center mb-16">
                    <h2 className="text-3xl sm:text-4xl font-bold mb-4">Green Choices, Greater Impact</h2>
                    <p className="text-lg sm:text-xl mb-8">Sustainability simplified by ThEarthify</p>
                    <button
                        className="btn btn-primary bg-green-600 hover:bg-green-700 border-none text-white"
                        onClick={() => navigate('/inquiry')}
                    >
                        Learn More <ArrowRight className="w-4 h-4 ml-2" />
                    </button>
                </section>

                {/* About Section */}
                <section id="about" className="relative h-[56vh] mb-12 sm:mb-16">
                    <img
                        src="/assets/earthify_bg.jpg"
                        alt="Eco-friendly concept"
                        className="w-full h-full object-cover"
                    />
                    <div className="absolute top-0 right-0 w-full sm:w-1/2 h-full bg-black bg-opacity-50 flex items-center">
                        <div className="text-white p-6 sm:p-8">
                            <h3 className="text-xl sm:text-2xl font-bold mb-4">About ThEarthify</h3>
                            <p className="mb-4">
                                At ThEarthify (a unit of FEPL), our goal is to provide quality, eco-friendly packaging solutions
                                with sustainability in mind. Our wholesale packaging options have catered not only to B2B but
                                also B2C markets across industries in India, Canada, and the United States of America.
                            </p>
                            <p>
                                Our products include various container and take-away box options, using biodegradable materials
                                like bagasse, a byproduct of sugarcane processing.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Team Section */}
                <section id="team" className="mb-16">
                    <h3 className="text-2xl sm:text-3xl font-bold mb-4 text-emerald-800">Meet the Team</h3>
                    {/* Team Member: Manuel Fernandes */}
                    <div className="card bg-white shadow-xl mb-8">
                        <div className="card-body">
                            <h4 className="card-title text-emerald-700">Manuel Fernandes</h4>
                            <p className="font-semibold text-emerald-600">Founder</p>
                            <p className="mb-4">
                                Manuel's career spans over 3 decades as a leader. He is widely known for improving the bottom line of organizations. He has set multiple greenfield projects, enhanced scalability, and created an employer brand. Manuel has worked with small, medium, and large organizations globally.
                            </p>
                            <ul className="list-disc pl-6 mb-4">
                                <li>Manuel’s experience spans a multitude of industry domains.</li>
                                <li>Manuel has an MBA in Strategy & HR from the Institute of Public Enterprise (IPE).</li>
                                <li>Manuel possesses a transformational leadership style and is a strong people’s person with good interpersonal skills.</li>
                            </ul>
                        </div>
                    </div>

                    {/* Team Member: Syed Khaleel */}
                    <div className="card bg-white shadow-xl">
                        <div className="card-body">
                            <h4 className="card-title text-emerald-700">Syed Khaleel</h4>
                            <p className="font-semibold text-emerald-600">Head of Sales and Marketing</p>
                            <p className="mb-4">
                                With a Sales & Marketing exposure across India & overseas in various domains like automotive & industrial lubricants, Pharma, and various products and services.
                            </p>
                            <p className="mb-4">
                                Extensive experience spanning more than 2 decades across 5 industries. Setting up business units/verticals and operations from the grassroots level. A Business Acumen and Analyst specialized in Treasury management, CAPEX & OPEX, Product segmentation & Placement.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Products Section */}
                <section id="products" className="text-center mb-16">
                    <h3 className="text-2xl sm:text-3xl font-bold mb-4 text-emerald-800">Our Products</h3>
                    <p className="text-lg sm:text-xl mb-8">
                        Explore our range of sustainable packaging solutions designed to make a positive environmental impact.
                    </p>
                    <button
                        className="btn btn-primary bg-green-600 hover:bg-green-700 border-none text-white"
                        onClick={() => navigate('/products')}
                    >
                        View Products <ArrowRight className="w-4 h-4 ml-2" />
                    </button>
                </section>
            </main>

            {/* Footer */}
            <footer className="bg-green-800 text-white p-4">
                <div className="container mx-auto text-center">
                    <p>&copy; 2024 ThEarthify. All rights reserved.</p>
                    <p>Together, let's make a greener future!</p>
                </div>
            </footer>
        </div>
    );
};

export default ThearthifyLandingPage;
